import React, { useState, useRef } from "react";
import { Button, Tab, Tabs, Alert } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import sizzleLogo from "../images/sizzle-logo.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// Özel Tab Component'i
const CustomTab = ({ active, onClick, icon, children }) => (
  <div
    onClick={onClick}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "8px",
      padding: "8px 16px",
      cursor: "pointer",
      background: active ? "#495057" : "transparent",
      color: active ? "white" : "#495057",
      borderRadius: "4px 4px 0 0",
      fontWeight: "600",
      minWidth: "120px",
      border: "none",
      transition: "background 0.2s, color 0.2s",
    }}
  >
    <i className={`fas ${icon}`}></i>
    {children}
  </div>
);

const TutorialPanel = ({ isOpen, onClose }) => {
  const [text, setText] = useState("");
  const [activeTab, setActiveTab] = useState("tutorial");
  const [copied, setCopied] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showMetadata, setShowMetadata] = useState(false);
  const quillRef = useRef(null);

  const tutorialContent = `


# Sizzle Story Management

## 🚀 Getting Started

### Story Creation Journey

1. **📝 Create Your Story**
   - Navigate to Stories page
   - Click "Create Story" button
   - Fill in required details:
     - Title (required)
     - Description (helps readers understand your story)
     - Sub Title (optional)
     - URL (optional, for external references)
     - Rating Count & Custom Rating (for story metrics)
   - 🏷️ Select appropriate tags:
     - Main Tag (primary category)
     - Sub Tags (additional categories)
   - Choose relevant sections
   - Save your story

2. **Chapter Management Phase**
   - Go to Chapters page
   - Find your story in the list
   - Click "Add Chapter" button
   - For each chapter:
     - Set chapter name
     - Assign position number (determines reading order)
     - Write/paste content using HTML Output format
     - Preview content before saving
     - Save chapter

## 🎯 Pro Tips for Success

### 📋 Planning Phase
- Map out your story structure
- Create a chapter outline
- Plan your tag strategy
- Think about reader flow

### ⚡ Quick Tips

   - Use HTML Output for better readability
   - Keep formatting consistent
   - Preview before saving
   - Check position numbers
   - Use appropriate tags



### 🚫 Common Pitfalls
   - Skipping preview step
   - Inconsistent formatting
   - Duplicate position numbers
   - Missing tags or sections
   - Unorganized chapter structure

## 🌟 Best Practices

1. **Organization**
   - Plan before creating
   - Use clear chapter names
   - Maintain logical order

2. **Quality Control**
   - Always preview
   - Check formatting
   - Verify positions
   - Test all features

3. **Workflow**
   - Story first
   - Tags second
   - Chapters last
   - Review all

## 🎉 Ready to Create?

You're now equipped with everything you need to create amazing stories on Sizzle. 
Remember, great stories come from great organization and attention to detail.

Happy Creating! 🚀
`;

  // Image modal component
  const ImageModal = ({ src, onClose }) => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0,0,0,0.8)",
        zIndex: 1100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={onClose}
    >
      <img
        src={src}
        alt="Enlarged view"
        style={{
          maxWidth: "90%",
          maxHeight: "90vh",
          objectFit: "contain",
          border: "2px solid white",
          borderRadius: "4px",
        }}
      />
    </div>
  );

  // Custom image renderer for ReactMarkdown
  const imageRenderer = ({ src, alt }) => {
    // Logo için özel stil
    if (alt === "Sizzle Logo") {
      return (
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img
            src={src}
            alt={alt}
            style={{
              width: "150px",
              height: "auto",
            }}
          />
        </div>
      );
    }

    // Diğer resimler için normal stil
    return (
      <img
        src={src}
        alt={alt}
        style={{
          maxWidth: "100%",
          height: "auto",
          cursor: "pointer",
          border: "1px solid #dee2e6",
          borderRadius: "4px",
          marginBottom: "5px",
        }}
        onClick={() => setSelectedImage(src)}
      />
    );
  };

  const convertToHTML = (text) => {
    if (!text) return "";
    return (
      text
        // HTML entities'i düzelt
        .replace(/&nbsp;/g, " ")
        .replace(/&rsquo;/g, "'")
        .replace(/&ldquo;/g, '"')
        .replace(/&rdquo;/g, '"')
        // Paragraf etiketlerini sadece satır sonuna çevir
        .replace(/<p[^>]*>/gi, "")
        .replace(/<\/p>/gi, "\n")
        // Sadece em ve strong etiketlerini koru, diğerlerini temizle
        .replace(/<(?!\/?em|\/?strong)[^>]+>/g, "")
        // Fazla boşlukları temizle
        .replace(/\s+\n/g, "\n")
        .replace(/\n\s+/g, "\n")
        .trim()
    );
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(convertToHTML(text));
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        right: 0,
        top: 0,
        bottom: 0,
        width: "600px",
        background: "white",
        boxShadow: "-2px 0 15px rgba(0,0,0,0.15)",
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
        borderLeft: "3px solid #495057",
      }}
    >
      <div
        style={{
          padding: "15px",
          borderBottom: "1px solid #dee2e6",
          background: "#495057",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h5
          style={{
            margin: 0,
            color: "#f8f9fa",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img
            src={sizzleLogo}
            alt="Sizzle"
            style={{
              width: "30px",
              height: "30px",
            }}
          />
          <span>Sizzle Story Guide</span>
        </h5>
        <button
          onClick={onClose}
          style={{
            border: "none",
            background: "none",
            fontSize: "24px",
            cursor: "pointer",
            color: "#f8f9fa",
            opacity: 0.8,
            transition: "opacity 0.2s",
          }}
          onMouseOver={(e) => (e.target.style.opacity = 1)}
          onMouseOut={(e) => (e.target.style.opacity = 0.8)}
        >
          ×
        </button>
      </div>

      <div
        style={{
          borderBottom: "1px solid #dee2e6",
          background: "#f8f9fa",
          padding: "8px 16px 0",
        }}
      >
        <div style={{ display: "flex", gap: "4px" }}>
          <CustomTab
            active={activeTab === "tutorial"}
            onClick={() => setActiveTab("tutorial")}
            icon="fa-book"
          >
            Guide
          </CustomTab>
          <CustomTab
            active={activeTab === "converter"}
            onClick={() => setActiveTab("converter")}
            icon="fa-magic"
          >
            Converter
          </CustomTab>
        </div>
      </div>

      <div style={{ flex: 1, overflow: "hidden" }}>
        {activeTab === "tutorial" && (
          <div
            style={{
              padding: "20px",
              overflowY: "auto",
              height: "calc(100vh - 130px)",
            }}
          >
            <ReactMarkdown components={{ img: imageRenderer }}>
              {tutorialContent}
            </ReactMarkdown>
          </div>
        )}
        {activeTab === "converter" && (
          <div
            style={{
              padding: "20px",
              height: "calc(100vh - 130px)",
              overflowY: "auto",
            }}
          >
            {/* Input Section */}
            <div style={{ marginBottom: "30px" }}>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center gap-2">
                  <i className="fas fa-edit" style={{ color: "#495057" }}></i>
                  <h6
                    style={{ margin: 0, color: "#495057", fontWeight: "600" }}
                  >
                    Text Editor
                  </h6>
                </div>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => setText("")}
                  disabled={!text}
                >
                  Clear
                </Button>
              </div>

              <Alert
                variant="info"
                className="mb-3"
                style={{ fontSize: "0.85rem" }}
              >
                <i className="fas fa-info-circle me-2"></i>
                Paste your text directly from Google Docs. Italic and bold
                formatting will be preserved.
              </Alert>

              <div style={{ height: "250px" }}>
                <ReactQuill
                  ref={quillRef}
                  value={text}
                  onChange={setText}
                  modules={{
                    toolbar: [["bold", "italic"], ["clean"]],
                  }}
                  formats={["bold", "italic"]}
                  style={{
                    height: "100%",
                  }}
                  theme="snow"
                  preserveWhitespace={true}
                />
              </div>
            </div>

            {/* Output Section */}
            <div style={{ marginTop: "50px" }}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center gap-2">
                  <i className="fas fa-code" style={{ color: "#495057" }}></i>
                  <h6
                    style={{ margin: 0, color: "#495057", fontWeight: "600" }}
                  >
                    HTML Output
                  </h6>
                </div>
                <Button
                  size="sm"
                  variant={copied ? "success" : "outline-primary"}
                  onClick={handleCopy}
                  disabled={!text}
                >
                  <i
                    className={`fas ${copied ? "fa-check" : "fa-copy"} me-1`}
                  ></i>
                  {copied ? "Copied!" : "Copy"}
                </Button>
              </div>

              <Alert
                variant="info"
                className="mb-3"
                style={{ fontSize: "0.85rem" }}
              >
                <i className="fas fa-info-circle me-2"></i>
                Review the HTML output before copying. You can paste this
                directly into your chapter content. Be careful true formatting.
                Pls check the output before pasting.
              </Alert>

              <div
                style={{
                  height: "250px",
                  padding: "15px",
                  border: "1px solid #dee2e6",
                  borderRadius: "4px",
                  overflowY: "auto",
                  background: "#fff",
                  fontSize: "14px",
                  fontFamily: "monospace",
                  whiteSpace: "pre-wrap",
                  lineHeight: "1.5",
                }}
              >
                {convertToHTML(text)}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Image modal */}
      {selectedImage && (
        <ImageModal
          src={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}

      <style>
        {`
          .tutorial-tabs .nav-link {
            border: none !important;
            background: transparent !important;
            padding: 0 !important;
            margin: 0 4px !important;
          }

          .tutorial-tabs .nav-link:hover,
          .tutorial-tabs .nav-link:focus,
          .tutorial-tabs .nav-link:active {
            border: none !important;
            background: transparent !important;
            outline: none !important;
            box-shadow: none !important;
          }

          .tutorial-tabs .nav-link.active {
            border: none !important;
          }

          /* Bootstrap'in hover stilini tamamen kaldır */
          .nav-tabs .nav-link:not(.active):hover {
            border-color: transparent !important;
            background-color: transparent !important;
            isolation: isolate;
          }

          /* Bootstrap'in focus stilini kaldır */
          .nav-tabs .nav-link:focus {
            border-color: transparent !important;
            outline: none !important;
            box-shadow: none !important;
          }
        `}
      </style>
    </div>
  );
};

export default TutorialPanel;
