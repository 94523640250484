import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Stories from "./component/stories";
import Contents from "./component/content";
import Story from "./component/stories/story";
import Chapters from "./component/chapters";
import Tags from "./component/tags";
import ScrollStory from "./component/scrollStory";
import Login from "./component/login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./component/profile";
import { isAuthenticated, getCurrentUser } from "./utils/supabaseClient";

const LoadingSpinner = () => (
  <div className="loading-container">
    <div className="spinner"></div>
    <p>Yükleniyor...</p>
  </div>
);

const PrivateRoute = ({ children }) => {
  const [isAuthed, setIsAuthed] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;
    const checkAuth = async () => {
      try {
        const authed = isAuthenticated();
        if (authed) {
          const user = await getCurrentUser();
          if (!user || !user.is_admin) {
            navigate("/login");
            return;
          }
          if (mounted) {
            setIsAuthed(true);
          }
        } else {
          if (mounted) {
            setIsAuthed(false);
          }
        }
      } catch (error) {
        console.error("Auth check error:", error);
        if (mounted) {
          setIsAuthed(false);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    checkAuth();
    return () => {
      mounted = false;
    };
  }, [navigate]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return isAuthed ? children : <Navigate to="/login" />;
};

const PublicRoute = ({ children }) => {
  const auth = isAuthenticated();
  return auth ? <Navigate to="/" /> : children;
};

const routes = [
  { path: "/login", element: <Login />, isPrivate: false },
  { path: "/", element: <Stories />, isPrivate: true },
  { path: "/story/:id", element: <Story />, isPrivate: true },
  { path: "/contents", element: <Contents />, isPrivate: true },
  { path: "/story/create", element: <Story />, isPrivate: true },
  {
    path: "/stories/:storyId/chapters",
    element: <Chapters />,
    isPrivate: true,
  },
  { path: "/tags", element: <Tags />, isPrivate: true },
  { path: "/chapters", element: <Chapters />, isPrivate: true },
  { path: "/scrollstory", element: <ScrollStory />, isPrivate: true },
  { path: "/profile", element: <Profile />, isPrivate: true },
];

const App = () => {
  return (
    <div className="App wrapper">
      <Router>
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                route.isPrivate ? (
                  <PrivateRoute>{route.element}</PrivateRoute>
                ) : (
                  <PublicRoute>{route.element}</PublicRoute>
                )
              }
            />
          ))}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
};

export default App;
