import React, { useState, useEffect } from "react";
import { Container, Form, Button, Alert, Row, Col } from "react-bootstrap";
import MainLayout from "../MainLayout";
import { getCurrentUser, changePassword } from "../../utils/supabaseClient";

const Profile = () => {
  const [user, setUser] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    const currentUser = await getCurrentUser();
    setUser(currentUser);
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage({ type: "danger", text: "Passwords do not match" });
      return;
    }

    const result = await changePassword(newPassword);
    if (result.success) {
      setMessage({ type: "success", text: "Password changed successfully" });
      setNewPassword("");
      setConfirmPassword("");
    } else {
      setMessage({ type: "danger", text: result.error });
    }
  };

  if (!user) return <MainLayout title="Profile">Loading...</MainLayout>;

  return (
    <MainLayout title="Profile">
      {message && (
        <Alert
          variant={message.type}
          onClose={() => setMessage(null)}
          dismissible
        >
          {message.text}
        </Alert>
      )}

      <Row className="mt-4">
        <Col md={6}>
          <h4>Change Password</h4>
          <Form onSubmit={handlePasswordChange}>
            <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Button type="submit">Change Password</Button>
          </Form>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default Profile;
