import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { supabase, storeUserSession } from "../../utils/supabaseClient";
import logo from "../images/sizzle-logo.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Query users table directly
      const { data, error } = await supabase
        .from("users")
        .select("id, email, is_admin, created_at, updated_at")
        .eq("email", email)
        .eq("password", password)
        .single();

      if (error) {
        toast.error("Login failed");
        return;
      }

      if (!data) {
        toast.error("Email or password is incorrect");
        return;
      }

      if (!data.is_admin) {
        toast.error("You do not have permission to access this panel.");
        return;
      }

      // Güvenli session saklama
      storeUserSession(data);

      toast.success("Login successful!");
      navigate("/");
    } catch (error) {
      console.error("Login error:", error);
      toast.error(error.message || "Login failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <div className="text-center mb-4">
          <img src={logo} alt="Sizzle Logo" style={{ width: "100px" }} />
        </div>
        <div className="card">
          <div className="card-body">
            <h2 className="text-center mb-4">Sizzle Story Management</h2>
            <Form onSubmit={onSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Email adress"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Şifre</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  placeholder="Password"
                />
              </Form.Group>

              <Button
                type="submit"
                className="w-100 mb-3"
                disabled={loading || !email || !password}
              >
                {loading ? "Logging in..." : "Login"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Login;
