import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import classNames from "classnames";
import {
  FaHome,
  FaBriefcase,
  FaTags,
  FaBookOpen,
  FaStream,
  FaSignOutAlt,
  FaTimes,
  FaUserCircle,
} from "react-icons/fa";
import logo from "../images/sizzle-logo.png";
import "./sidebar.css";
import { logout, getCurrentUser } from "../../utils/supabaseClient";

const SideBar = ({ isOpen, toggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    checkAdminStatus();
  }, []);

  const checkAdminStatus = async () => {
    try {
      const user = await getCurrentUser();
      setIsAdmin(!!user && user.is_admin);
    } catch (error) {
      console.error("Error checking admin status:", error);
    }
  };

  const menuItems = [
    { path: "/", icon: <FaHome />, title: "Stories" },
    { path: "/contents", icon: <FaBriefcase />, title: "Sections" },
    { path: "/tags", icon: <FaTags />, title: "Tags" },
    { path: "/chapters", icon: <FaBookOpen />, title: "Chapters" },
    { path: "/scrollstory", icon: <FaStream />, title: "Scroll Story" },
    { path: "/profile", icon: <FaUserCircle />, title: "Profile" },
  ];

  const handleLogOut = () => {
    logout();
    navigate("/login");
  };

  return (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
      <div className="sidebar-header">
        <div className="logo-container">
          <img src={logo} alt="Sizzle Logo" className="logo" />
          <span className="logo-text">Sizzle Story</span>
        </div>
        <FaTimes className="close-btn" onClick={toggle} />
      </div>

      <div className="sidebar-content">
        <Nav className="flex-column">
          {menuItems.map((item) => (
            <Nav.Link
              key={item.path}
              href={item.path}
              className={`menu-item ${
                location.pathname === item.path ? "active" : ""
              }`}
            >
              <span className="icon">{item.icon}</span>
              <span className="title">{item.title}</span>
            </Nav.Link>
          ))}
        </Nav>
      </div>

      <div className="logout" onClick={handleLogOut}>
        <span className="icon">
          <FaSignOutAlt />
        </span>
        <span className="title">Logout</span>
      </div>
    </div>
  );
};

export default SideBar;
