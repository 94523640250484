import React, { useEffect, useState } from "react";
import MainLayout from "../MainLayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Button,
  Container,
  Form as BootstrapForm,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Modal,
  Image,
} from "react-bootstrap";
import { MultiSelectField, SelectField, TextField } from "../formFields/Fields";
import { transformedSectionList, transformedTagList } from "../constant";
import {
  getSectionIds,
  getSectionList,
  getStory,
  getTagIds,
  getTagList,
  updateStory,
  createStory,
  sendTelegramNotification,
} from "../../utils/endpoints";
import Loader from "../Loader";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CreateChapter from "../chapters/createChapter";
import { deleteFromS3, uploadToS3 } from "../../utils/uploadImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCheckCircle,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { validationSchema } from "./validation";
import { supabase } from "../../utils/supabaseClient";
import squooshSettings from "../images/squoosh_settings.png";

const initialV = {
  title: "",
  url: "",
  sub_title: "",
  description: "",
  story_status: "",
  tag: "",
  tag_type: "main_tag",
  section: [],
  sub_tag: [],
  rating_count: "",
  custom_rating: "",
  premium: false,
  preview_chapter_count: "",
};

const Story = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tagList, setTagList] = useState([]);
  const [section, setSection] = useState([]);
  const [createdStory, setCreatedStory] = useState(false);
  const [loader, setLoader] = useState(false);
  const [initialValues, setInitialValues] = useState(initialV);
  const [storyData, setStoryData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [showSizeWarningModal, setShowSizeWarningModal] = useState(false);
  const [pendingUpload, setPendingUpload] = useState(null);
  const [showOptimizationModal, setShowOptimizationModal] = useState(false);
  const [optimizationCallback, setOptimizationCallback] = useState(null);
  const [showChaptersModal, setShowChaptersModal] = useState(false);
  const [chaptersList, setChaptersList] = useState([]);
  const [expandedChapter, setExpandedChapter] = useState(null);

  const subTagIds = (tags) =>
    tags.filter((tag) => tag.tag_types === "sub_tag").map((tag) => tag.tag_id);

  const secIds = (sec) => sec.map((tag) => tag.section_id);

  const mainTagIds = (tags) => {
    const mainTag = tags
      .filter((tag) => tag.tag_types === "main_tag")
      .map((tag) => tag.tag_id);
    if (Array.isArray(mainTag)) {
      return mainTag[0];
    } else if (typeof mainTag === "number" || typeof mainTag === "string") {
      return mainTag;
    }
    return null;
  };

  const fetchChapters = async () => {
    try {
      const { data, error } = await supabase
        .from("chapters")
        .select("*")
        .eq("story_id", id)
        .order("created_at", { ascending: true });

      if (error) throw error;
      setChaptersList(data || []);
    } catch (error) {
      console.error("Error fetching chapters:", error);
      toast.error("Failed to load chapters");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tagData = await getTagList();
        setTagList(transformedTagList(tagData?.data) || []);

        const sectionData = await getSectionList();
        setSection(transformedSectionList(sectionData?.data) || []);

        if (id) {
          setLoader(true);
          const story = await getStory(id);
          const tagIds = await getTagIds(id);
          const sectionIds = await getSectionIds(id);
          if (story?.data) {
            const storyData = {
              ...initialV,
              title: story.data[0].title,
              description: story.data[0].description,
              story_status: story.data[0].story_status,
              sub_title: story.data[0].sub_title,
              rating_count: story.data[0].rating_count || "",
              custom_rating: story.data[0].custom_rating || "",
              url: story.data[0].url,
              sub_tag: subTagIds(tagIds.data),
              tag: mainTagIds(tagIds.data),
              section: secIds(sectionIds.data),
              premium: story.data[0].premium || false,
              preview_chapter_count: story.data[0].preview_chapter_count || "",
            };
            setInitialValues(storyData);
            setLoader(false);
          }
        }
      } catch (error) {
        toast.error("Failed to load the story!");
        setLoader(false);
      }
    };

    fetchData();
  }, [id]);

  const handleCreate = async (formattedValues, setLoader, navigate) => {
    setLoader(true);
    try {
      const { data, error } = await createStory(formattedValues);
      if (error) throw error;

      // Get tag and section names
      const mainTag =
        tagList.find((t) => t.value === formattedValues.tag)?.label || "None";
      const subTags =
        formattedValues.sub_tag
          ?.map((id) => tagList.find((t) => t.value === id)?.label)
          .filter(Boolean)
          .join(", ") || "None";
      const sections =
        formattedValues.section
          ?.map((id) => section.find((s) => s.value === id)?.label)
          .filter(Boolean)
          .join(", ") || "None";

      // Yeni hikaye oluşturulduğunda Telegram bildirimi gönder
      try {
        const message =
          `🆕 New Story Added!\n\n` +
          `📖 Title: ${formattedValues.title}\n` +
          `📝 Subtitle: ${formattedValues.sub_title || "None"}\n` +
          `💫 Premium: ${formattedValues.premium ? "Yes" : "No"}\n` +
          `${
            formattedValues.premium
              ? `🔓 Free Chapters: ${
                  formattedValues.preview_chapter_count || 0
                }\n`
              : ""
          }` +
          `📋 Description: ${
            formattedValues.description ? formattedValues.description : "None"
          }\n` +
          `🔗 Image URL: ${formattedValues.url || "None"}\n` +
          `📊 Rating: ${formattedValues.custom_rating || 0}\n` +
          `👥 Rating Count: ${formattedValues.rating_count || 0}\n` +
          `🏷️ Status: ${formattedValues.story_status || "Not specified"}\n` +
          `🔖 Main Tag: ${mainTag}\n` +
          `📑 Sub Tags: ${subTags}\n` +
          `📚 Sections: ${sections}`;

        const response = await fetch(
          `https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/sendMessage`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              chat_id: process.env.REACT_APP_TELEGRAM_CHAT_ID,
              text: message,
              parse_mode: "HTML",
            }),
          }
        );

        if (!response.ok) {
          console.error("Telegram notification failed");
        }
      } catch (telegramError) {
        console.error("Failed to send Telegram notification:", telegramError);
        // Bildirimin başarısız olması hikaye oluşturmayı etkilemesin
      }

      toast.success("Story Created Successfully!");
      navigate("/");
    } catch (error) {
      toast.error(error.message);
    }
    setLoader(false);
  };

  const handleSubmit = async (values) => {
    const formattedValues = {
      title: values.title,
      description: values.description,
      story_status: values.story_status,
      sub_title: values.sub_title,
      url: values.url,
      rating_count: parseInt(values.rating_count || 0),
      custom_rating: parseFloat(values.custom_rating) || 0,
      tag: values.tag,
      sub_tag: values.sub_tag || [],
      section: values.section || [],
      premium: values.premium || false,
      preview_chapter_count: values.premium
        ? parseInt(values.preview_chapter_count || 0)
        : null,
    };

    if (id) {
      setLoader(true);
      try {
        const result = await updateStory(formattedValues, id);
        console.log("Update result:", result);
        toast.success("Story updated successfully");
        navigate("/");
      } catch (error) {
        console.error("Update error:", error);
        toast.error("Failed to update story");
      }
      setLoader(false);
    } else {
      handleCreate(formattedValues, setLoader, navigate);
    }
  };

  const handleClose = () => {
    setCreatedStory(false);
  };

  const handleImageUpload = async (setFieldValue, event, values) => {
    if (!event.currentTarget.files || !event.currentTarget.files[0]) {
      return;
    }

    const file = event.currentTarget.files[0];
    const fileSizeKB = file.size / 1024;

    if (fileSizeKB > 150) {
      setPendingUpload({
        file,
        setFieldValue,
        values,
        fileSizeKB,
      });
      setShowSizeWarningModal(true);
    } else {
      await processUpload(file, setFieldValue, values, fileSizeKB);
    }
  };

  const processUpload = async (file, setFieldValue, values, fileSizeKB) => {
    try {
      setLoading(true);
      console.log(`Uploading file size: ${fileSizeKB} KB`);

      // Upload to S3 via Edge Function
      const { url, originalSize } = await uploadToS3(file);

      // Set the URL in the form
      setFieldValue("url", url);

      // Send Telegram notification
      const message =
        `New Image Uploaded\n\n` +
        `📊 Size: ${(originalSize / 1024).toFixed(2)}KB` +
        (originalSize / 1024 > 150 ? " (⚠️ Large file!)" : " (✅ Optimized)") +
        "\n" +
        `📝 Story: ${values.title || "Untitled"}\n` +
        `🔗 Link: ${url}`;

      await sendTelegramNotification(message);
      setUploadDone(true);
    } catch (error) {
      toast.error("Image upload failed. Please try again.");
      console.error("Upload Error:", error);
    } finally {
      setLoading(false);
      setShowOptimizationModal(false);
    }
  };

  const callback = (chapter) => {
    handleClose();
    const formattedStoryData = {
      title: storyData.title,
      description: storyData.description,
      story_status: storyData.story_status,
      sub_title: storyData.sub_title,
      url: storyData.url,
      rating_count: parseInt(storyData.rating_count || 0),
      custom_rating: parseFloat(storyData.custom_rating) || 0,
    };

    // Tag ve section verilerini ayrı gönderelim
    const tagData = {
      tag: storyData.tag,
      sub_tag: storyData.sub_tag || [],
    };

    const sectionData = {
      section: storyData.section || [],
    };

    console.log("Creating new story with data:", {
      story: formattedStoryData,
      tags: tagData,
      sections: sectionData,
    });

    handleCreate(
      formattedStoryData,
      chapter,
      setLoader,
      navigate,
      tagData,
      sectionData
    );
  };

  const checkFileSize = async (url) => {
    try {
      const response = await fetch(url);
      const size = response.headers.get("content-length");
      console.log(`File size: ${size / 1024} KB`);
      return size / 1024; // KB cinsinden
    } catch (error) {
      console.error("Size check error:", error);
      return null;
    }
  };

  return (
    <MainLayout title="Story">
      <Container>
        {loader ? (
          <Loader />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue }) => {
              const ChaptersModal = () => (
                <Modal
                  show={showChaptersModal}
                  onHide={() => setShowChaptersModal(false)}
                  size="lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Story Chapters</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="chapters-list">
                      {chaptersList.length > 0 ? (
                        <table className="table chapter-table">
                          <thead>
                            <tr>
                              <th>Position</th>
                              <th>Chapter Name</th>
                              <th>Content</th>
                              <th style={{ width: "200px" }}>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {chaptersList.map((chapter, index) => {
                              const isFreeChapter =
                                !values.premium ||
                                index <
                                  parseInt(values.preview_chapter_count || 0);

                              return (
                                <tr key={chapter.id} className="chapter-row">
                                  <td>{chapter.position}</td>
                                  <td>{chapter.chapter_name}</td>
                                  <td>
                                    <div
                                      style={{
                                        maxHeight:
                                          expandedChapter === chapter.id
                                            ? "none"
                                            : "75px",
                                        overflow:
                                          expandedChapter === chapter.id
                                            ? "visible"
                                            : "hidden",
                                        position: "relative",
                                        whiteSpace: "pre-wrap",
                                        fontFamily: "inherit",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: chapter.content,
                                        }}
                                      />
                                      {expandedChapter !== chapter.id && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            height: "25px",
                                            background:
                                              "linear-gradient(transparent, white)",
                                          }}
                                        />
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex flex-column gap-2">
                                      <span
                                        className={`badge ${
                                          isFreeChapter
                                            ? "badge-free"
                                            : "badge-premium"
                                        }`}
                                      >
                                        {isFreeChapter ? "Free" : "Premium"}
                                      </span>
                                      {expandedChapter !== chapter.id ? (
                                        <Button
                                          variant="outline-primary"
                                          size="sm"
                                          className="view-content-btn"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setExpandedChapter(chapter.id);
                                          }}
                                        >
                                          View Content
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="outline-secondary"
                                          size="sm"
                                          className="view-content-btn"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setExpandedChapter(null);
                                          }}
                                        >
                                          Show Less
                                        </Button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="text-center p-4">
                          <p className="mb-0">
                            No chapters found for this story.
                          </p>
                        </div>
                      )}
                    </div>
                  </Modal.Body>
                </Modal>
              );

              return (
                <Form>
                  <Row className="mt-20">
                    <Col md={4}>
                      <TextField name="title" label="Title" />
                    </Col>
                    <Col md={4}>
                      <TextField name="rating_count" label="Rating Count" />
                    </Col>
                    <Col md={4}>
                      <div className="form-group">
                        <label className="form-title">Rating</label>
                        <div className="d-flex align-items-center">
                          <input
                            type="range"
                            min="0"
                            max="50"
                            value={(values.custom_rating || 0) * 10}
                            className="form-range flex-grow-1 me-2"
                            onChange={(e) => {
                              const value = parseFloat(e.target.value) / 10;
                              setFieldValue("custom_rating", value);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                          <span
                            className="ms-2 fw-bold"
                            style={{ minWidth: "40px" }}
                          >
                            {values.custom_rating || "0.0"}
                          </span>
                        </div>
                        <ErrorMessage
                          name="custom_rating"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-20">
                    <Col md={6}>
                      <TextField name="sub_title" label="Sub Title" />
                    </Col>
                    <Col md={6}>
                      <TextField name="story_status" label="Status" />
                    </Col>
                  </Row>
                  <Row className="mt-20">
                    <Col md={6}>
                      <BootstrapForm.Group>
                        <BootstrapForm.Label className="form-title">
                          Premium Story
                        </BootstrapForm.Label>
                        <div className="premium-switch-container">
                          <BootstrapForm.Check
                            type="switch"
                            id="premium-switch"
                            checked={values.premium}
                            onChange={(e) => {
                              setFieldValue("premium", e.target.checked);
                              if (!e.target.checked) {
                                setFieldValue("preview_chapter_count", "");
                              }
                            }}
                            label={values.premium ? "Premium" : "Free"}
                            disabled={!id}
                          />
                          <div className="form-help-text">
                            {!id
                              ? "First create the story and add chapters, then you can set premium options"
                              : "Set story as premium to enable preview chapters"}
                          </div>
                        </div>
                      </BootstrapForm.Group>
                    </Col>
                    <Col md={6}>
                      <BootstrapForm.Group>
                        <div className="d-flex justify-content-between align-items-center">
                          <BootstrapForm.Label className="form-title">
                            Free Preview Chapters
                          </BootstrapForm.Label>
                          {id && (
                            <Button
                              variant="link"
                              className="p-0 mb-2"
                              onClick={() => {
                                fetchChapters();
                                setShowChaptersModal(true);
                              }}
                            >
                              <FontAwesomeIcon icon={faList} className="me-1" />
                              Chapters
                            </Button>
                          )}
                        </div>
                        <div className="preview-chapters-container">
                          <BootstrapForm.Control
                            type="number"
                            value={values.preview_chapter_count}
                            onChange={(e) =>
                              setFieldValue(
                                "preview_chapter_count",
                                e.target.value
                              )
                            }
                            disabled={!id || !values.premium}
                            min="0"
                            placeholder={
                              !id
                                ? "Create story first to set preview chapters"
                                : values.premium
                                ? "Enter number of free chapters"
                                : "Only available for premium stories"
                            }
                          />
                          <div className="form-help-text">
                            {!id
                              ? "First create the story and add chapters, then you can set preview chapters"
                              : values.premium
                              ? "Set number of free preview chapters for premium story"
                              : "Enable premium to set free preview chapters"}
                          </div>
                        </div>
                      </BootstrapForm.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <BootstrapForm.Group controlId="image-preview">
                        <BootstrapForm.Label className="form-title">
                          Current Image
                        </BootstrapForm.Label>
                        {values.url ? (
                          <div className="image-preview-container">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="image-tooltip">
                                  Click to view details
                                </Tooltip>
                              }
                            >
                              <div
                                style={{
                                  width: "100px",
                                  paddingBottom: "137px",
                                  position: "relative",
                                  border: "1px solid #dee2e6",
                                  borderRadius: "8px",
                                  overflow: "hidden",
                                  backgroundColor: "#f8f9fa",
                                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                }}
                              >
                                <Image
                                  src={values.url}
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100px",
                                    height: "137px",
                                    objectFit: "cover",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setSelectedImageUrl(values.url);
                                    setShowImageModal(true);
                                  }}
                                  alt="Current story cover"
                                />
                              </div>
                            </OverlayTrigger>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "100px",
                              height: "137px",
                              position: "relative",
                              border: "2px dashed #dee2e6",
                              borderRadius: "8px",
                              backgroundColor: "#f8f9fa",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                textAlign: "center",
                              }}
                            >
                              <span className="text-muted">
                                No image uploaded
                              </span>
                            </div>
                          </div>
                        )}
                      </BootstrapForm.Group>
                    </Col>

                    <Col md={8}>
                      <BootstrapForm.Group controlId="image-upload">
                        <BootstrapForm.Label className="form-title">
                          Upload New Image
                        </BootstrapForm.Label>
                        <div className="position-relative">
                          <input
                            name="url"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            onChange={(event) =>
                              handleImageUpload(setFieldValue, event, values)
                            }
                          />
                          {loading && (
                            <FontAwesomeIcon
                              icon={faSpinner}
                              spin
                              className="position-absolute top-50 end-0 translate-middle"
                              style={{ color: "red" }}
                            />
                          )}
                          {uploadDone && !loading && (
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              size="lg"
                              className="position-absolute top-50 end-0 translate-middle"
                              style={{ color: "green" }}
                            />
                          )}
                        </div>
                        <ErrorMessage
                          name="url"
                          component="div"
                          className="text-danger"
                        />
                        {values.url && (
                          <div className="mt-2">
                            <small className="text-muted">
                              Current URL:
                              <span
                                className="text-primary ms-1 cursor-pointer"
                                onClick={() => {
                                  setSelectedImageUrl(values.url);
                                  setShowImageModal(true);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Click to view/copy
                              </span>
                            </small>
                          </div>
                        )}
                      </BootstrapForm.Group>
                    </Col>
                  </Row>
                  <BootstrapForm.Group
                    controlId="description"
                    className="mt-20"
                  >
                    <BootstrapForm.Label className="form-title">
                      Description
                    </BootstrapForm.Label>
                    <Field
                      name="description"
                      as="textarea"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-danger"
                    />
                  </BootstrapForm.Group>

                  <Row className="mt-20">
                    <Col>
                      <SelectField
                        name="tag"
                        label="Select Main Tag"
                        options={tagList}
                        main
                      />
                    </Col>
                    <Col className="text-center">
                      <MultiSelectField
                        name="sub_tag"
                        label="Select Sub Tag"
                        options={tagList}
                        mainId={values?.tag}
                      />
                    </Col>
                    <Col>
                      <MultiSelectField
                        name="section"
                        label="Select Sections"
                        options={section}
                      />
                    </Col>
                  </Row>

                  <Button
                    type="submit"
                    variant="primary"
                    className="float-right mt-20"
                    disabled={loading}
                  >
                    Submit
                  </Button>
                  <ChaptersModal />
                </Form>
              );
            }}
          </Formik>
        )}
      </Container>
      <CreateChapter
        show={createdStory}
        handleClose={handleClose}
        callback={callback}
        isStory
        storyData={storyData}
      />
      <Modal show={showImageModal} onHide={() => setShowImageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Image URL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center">
            <input
              type="text"
              value={selectedImageUrl}
              readOnly
              className="form-control"
            />
            <Button
              variant="outline-primary"
              className="ml-2"
              onClick={() => {
                navigator.clipboard.writeText(selectedImageUrl);
                toast.success("URL copied to clipboard!");
              }}
            >
              Copy
            </Button>
          </div>
          <div className="mt-3 text-center">
            <Image
              src={selectedImageUrl}
              style={{ maxWidth: "100%", maxHeight: "300px" }}
              alt="Selected story cover"
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSizeWarningModal}
        onHide={() => setShowSizeWarningModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="text-warning">⚠️ Big File Warning</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This image is {(pendingUpload?.fileSizeKB || 0).toFixed(2)}KB.</p>
          <p>Images larger than 150KB</p>
          <p>Recommended action:</p>
          <ol>
            <a
              href="https://squoosh.app/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Compress image using Squoosh with these settings
            </a>
            <div className="mt-2 mb-3">
              <img
                src={squooshSettings} // Import edilen resmi kullan
                alt="Recommended Squoosh settings"
                style={{
                  maxWidth: "40%",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
              />
            </div>
            Click "Upload Anyway" to continue without compression (Not
            recommended)
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSizeWarningModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="warning"
            onClick={async () => {
              setShowSizeWarningModal(false);
              if (pendingUpload) {
                await processUpload(
                  pendingUpload.file,
                  pendingUpload.setFieldValue,
                  pendingUpload.values,
                  pendingUpload.fileSizeKB
                );
                setPendingUpload(null);
              }
            }}
          >
            Upload Anyway
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showOptimizationModal}
        onHide={() => {
          setShowOptimizationModal(false);
          if (optimizationCallback) {
            optimizationCallback(null);
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Image Optimization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This image is larger than 100KB. Would you like to optimize it?</p>
          <p>
            Optimization will reduce the file size while maintaining good
            quality.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              if (optimizationCallback) {
                optimizationCallback(null);
              }
              setShowOptimizationModal(false);
            }}
          >
            Skip Optimization
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (optimizationCallback) {
                optimizationCallback("optimize");
              }
              setShowOptimizationModal(false);
            }}
          >
            Optimize Image
          </Button>
        </Modal.Footer>
      </Modal>
      <style>
        {`
          .chapter-table {
            border: 1px solid #dee2e6;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
          }

          .chapter-table thead {
            background-color: #f8f9fa;
          }

          .chapter-table th {
            border-bottom: 2px solid #dee2e6;
            padding: 12px;
          }

          .chapter-table td {
            padding: 12px;
            vertical-align: middle;
          }

          .chapter-row {
            border-bottom: 1px solid #dee2e6;
          }

          .chapter-row:last-child {
            border-bottom: none;
          }

          .badge-free {
            background-color: #0dcaf0;
            color: white;
            padding: 6px 12px;
            border-radius: 4px;
            font-weight: 500;
          }

          .badge-premium {
            background-color: #ffc107;
            color: #000;
            padding: 6px 12px;
            border-radius: 4px;
            font-weight: 500;
          }

          .view-content-btn {
            width: 100%;
          }

          .premium-switch-container,
          .preview-chapters-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }

          .form-help-text {
            font-size: 0.875rem;
            color: #6c757d;
          }
        `}
      </style>
    </MainLayout>
  );
};

export default Story;
