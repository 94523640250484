import { supabase, getCurrentUser } from "./supabaseClient";

export const uploadToS3 = async (file) => {
  try {
    // Check if user is authenticated and has admin role
    const user = await getCurrentUser();
    if (!user) {
      throw new Error("You must log in to upload.");
    }

    if (!user.is_admin) {
      throw new Error("Only admin users can upload files.");
    }

    const uniqueFileName = `${Date.now()}-${file.name.replace(
      /[^a-zA-Z0-9.]/g,
      ""
    )}`;

    // Create form data
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", uniqueFileName);

    // Call Supabase Edge Function for S3 upload
    const response = await fetch(
      `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/upload-to-s3`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SUPABASE_KEY}`,
          "x-user-email": user.email,
          "x-user-admin": user.is_admin.toString(),
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.error || "File upload failed. Please try again."
      );
    }

    const data = await response.json();
    if (!data.success)
      throw new Error("File upload failed. The server did not respond.");

    // Create CloudFront URL
    const cdnUrl = `https://${process.env.REACT_APP_CLOUDFRONT_DOMAIN}/${uniqueFileName}`;

    return {
      url: cdnUrl,
      originalSize: file.size,
    };
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const deleteFromS3 = async (fileUrl) => {
  try {
    // Check if user is authenticated and has admin role
    const user = await getCurrentUser();
    if (!user) {
      throw new Error("You must log in to delete a file.");
    }

    if (!user.is_admin) {
      throw new Error("Only admin users can delete files.");
    }

    // Extract filename from CloudFront URL
    const urlParts = fileUrl.split("/");
    const fileName = urlParts[urlParts.length - 1];

    // Call Supabase Edge Function for S3 deletion
    const response = await fetch(
      `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/delete-from-s3`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_SUPABASE_KEY}`,
          "x-user-email": user.email,
          "x-user-admin": user.is_admin.toString(),
        },
        body: JSON.stringify({ fileName }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.error || "File deletion failed. Please try again."
      );
    }

    const data = await response.json();
    if (!data.success)
      throw new Error("File deletion failed. Server did not respond");

    return true;
  } catch (error) {
    console.error("Error deleting file:", error);
    throw error;
  }
};
