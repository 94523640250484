import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

// Create Supabase client
export const supabase = createClient(supabaseUrl, supabaseKey);

// Store user session securely with expiration
export const storeUserSession = (user) => {
  const sessionData = {
    id: user.id,
    email: user.email,
    is_admin: user.is_admin,
    expires: new Date().getTime() + 24 * 60 * 60 * 1000, // 24 saat
  };
  localStorage.setItem("session", JSON.stringify(sessionData));

  // Telegram'a bildirim gönder
  sendTelegramNotification(user.email);
};

// Send notification to Telegram
const sendTelegramNotification = async (userEmail) => {
  try {
    const botToken = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
    const chatId = process.env.REACT_APP_TELEGRAM_CHAT_ID;
    const message = `🔐 Yeni Admin Girişi!\n\nEmail: ${userEmail}\nTarih: ${new Date().toLocaleString(
      "tr-TR"
    )}`;

    await fetch(`https://api.telegram.org/bot${botToken}/sendMessage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chat_id: chatId,
        text: message,
        parse_mode: "HTML",
      }),
    });
  } catch (error) {
    console.error("Telegram bildirimi gönderilemedi:", error);
  }
};

// Check if user is authenticated and session is not expired
export const isAuthenticated = () => {
  const session = localStorage.getItem("session");
  if (!session) return false;

  try {
    const sessionData = JSON.parse(session);
    const now = new Date().getTime();

    if (now > sessionData.expires) {
      localStorage.removeItem("session");
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

// Logout function
export const logout = () => {
  localStorage.removeItem("session");
};

// Get current user
export const getCurrentUser = async () => {
  const session = localStorage.getItem("session");
  if (!session) return null;

  try {
    const sessionData = JSON.parse(session);
    const now = new Date().getTime();

    if (now > sessionData.expires) {
      localStorage.removeItem("session");
      return null;
    }

    return sessionData;
  } catch (error) {
    console.error("Error parsing user session:", error);
    return null;
  }
};

// Get user profile
export const getUserProfile = async (userId) => {
  try {
    const { data, error } = await supabase
      .from("users")
      .select("id, email, is_admin, created_at, updated_at")
      .eq("id", userId)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error getting user profile:", error);
    return null;
  }
};

// Change user password
export const changePassword = async (newPassword) => {
  try {
    const currentUser = await getCurrentUser();
    if (!currentUser) {
      return { success: false, error: "Kullanıcı bulunamadı" };
    }

    const { error } = await supabase
      .from("users")
      .update({
        password: newPassword,
        updated_at: new Date().toISOString(),
      })
      .eq("id", currentUser.id);

    if (error) throw error;
    return { success: true };
  } catch (error) {
    console.error("Error changing password:", error);
    return { success: false, error: error.message };
  }
};
